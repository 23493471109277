import React from 'react';
import KanbanBoard from "./KanbanBoard";

const Kanban = () => {

    return (
        <div className='page-content home-page p-2'>

            <KanbanBoard/>

        </div>
    )

}

export default Kanban;
