import React, {createContext, useContext, useEffect, useState} from "react";
import KanbanColumn from "./KanbanColumn";
import ApiClient from "../../../lib/ApiClient";
import {ETaskStatus, IZenTask} from "@yellowmelon/zen-global-types";
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import {GlobalContext} from "../../../lib/GlobalContextProvider";
import FilterKanbanTasks from "./FilterKanbanTasks";
import * as querystring from "querystring";

export interface IKanbanFilters {
  contextId: string | null;
  filterToday: boolean;
  filterPriority: boolean;
  goalId: string | null;
}

interface IKanbanBoardContext {
    getTaskList: (filters?: IKanbanFilters) => void
}

export const DraggableItemTypes = {
    TASK: 'task'
}

export const KanbanBoardContext = createContext<IKanbanBoardContext>(
    {
        getTaskList: () => {}
    }
)

const KanbanBoard = () => {

    const apiClient = new ApiClient();
    const [taskList, setTaskList] = useState<IZenTask[]>([])
    const {showAlert} = useContext(GlobalAlertContext);
    const {currentContext, filterToday, availableContexts} = useContext(GlobalContext)
    
    const [filters, setFilters] = useState<IKanbanFilters>({
        contextId: null,
        filterToday: false,
        filterPriority: false,
        goalId: null
    });

    const getTaskList = (filters?: IKanbanFilters) => {
        const queryObject: {[key:string] : string} = {};

        if (filters?.contextId) {
            queryObject['context'] = filters.contextId;
        }

        if (filters?.filterToday) {
            queryObject['filtertoday'] = 'true';
        }

        if (filters?.filterPriority) {
            queryObject['filterpriority'] = 'true';
        }

        if (filters?.goalId) {
            queryObject['goalid'] = filters.goalId;
        }

        const queryString = querystring.stringify(queryObject);
        
        apiClient.get<IZenTask[]>(`api/v1/tasks${queryString ? `?${queryString}` : ''}`).then(
            (tasks) => setTaskList(tasks)
        ).catch(
            (err: any) => {
                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
            }
        );
    };

    useEffect(() => {
        getTaskList(filters);
    }, [filters]);

    return (
        <KanbanBoardContext.Provider value={{getTaskList}}>

            <div className='kanban-container'>

                <div className='task-management flex flex-col sm:flex-row items-start sm:items-center mb-8 gap-2'>
                    <div className="w-full sm:w-auto flex justify-end sm:justify-start">
                        <FilterKanbanTasks
                            filters={filters}
                            setFilters={setFilters}
                            contexts={availableContexts || []}
                        />
                    </div>
                </div>

                <div className={'kanban-column-container overflow-x-auto flex pb-10'}
                     style={{scrollSnapType: 'x mandatory'}}>

                    <KanbanColumn
                        key={ETaskStatus.inbox}
                        setTaskList={setTaskList}
                        status={ETaskStatus.inbox}
                        title='Inbox'
                        taskList={taskList}/>

                    <KanbanColumn
                        key={ETaskStatus.thisWeek}
                        setTaskList={setTaskList} status={ETaskStatus.thisWeek}
                        title='This week'
                        taskList={taskList}/>

                    <KanbanColumn
                        key={ETaskStatus.today}
                        setTaskList={setTaskList} status={ETaskStatus.today} title='Today'
                        taskList={taskList}/>

                    <KanbanColumn
                        key={ETaskStatus.done}
                        setTaskList={setTaskList} status={ETaskStatus.done} title='Done'
                        taskList={taskList}
                        showAddButton={false}
                    />

                </div>

            </div>

        </KanbanBoardContext.Provider>
    )

}

export default KanbanBoard
