import React from "react";
import {Route, Routes} from "react-router-dom";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import NotFound from "../pages/NotFound";
import Subscription from "../pages/Subscription";
import TopGoals from "../pages/TopGoals";
import Account from "../pages/Account";
import GoalPage from "../pages/GoalPage";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import Notes from "../pages/Notes";
import {AnimatePresence} from 'framer-motion';
import HomePage from "../pages/Home";
import Kanban from "../pages/Kanban";

const ZenRouter = () => {


    return (

        <AnimatePresence mode="wait">
            <Routes>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/kanban' element={<Kanban />}/>
                <Route path='/goals' element={<TopGoals/>}/>
                <Route path='/notes' element={<Notes/>}/>
                <Route path='zenauth/login' element={<Login/>}/>
                <Route path='zenauth/register' element={<Register/>}/>
                <Route path='zenauth/forgot-password' element={<ForgotPassword/>}/>
                <Route path='zenauth/reset-password' element={<ResetPassword/>}/>
                <Route path='zenauth/*' element={<NotFound/>}/>
                <Route path='/account' element={<Account/>}/>
                <Route path='subscription' element={<Subscription/>}/>
                <Route path='/goal/:goalid/*' element={<GoalPage/>}/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
        </AnimatePresence>
    )

}

export default ZenRouter
