import React, {createContext, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {ManageBearerToken} from "../lib/ManageBearerToken";
import {useLocation, useNavigate} from "react-router-dom";
import {ELLMRequestStatus, IUser} from "@yellowmelon/zen-global-types";
import {EAlertType, GlobalAlertContext} from "./GlobalAlert";

const tokenManager = new ManageBearerToken()

const nonAuthenticatedRoutes = [
    '/zenauth/login',
    '/zenauth/register',
    '/zenauth/forgot-password',
    '/zenauth/reset-password',
]

const websocketUrl = process.env.WEBSOCKET_URL

export type IClientUser = Omit<IUser, 'password' | 'forgotPassword'> & {
    llmStatus: ELLMRequestStatus;
    freeTrialExpires: Date | string | null;
}

export interface IAuthContext {
    user: IClientUser | null;
    authStatus: {
        loggedIn: boolean;
        authComplete: boolean;
    };
    doLogin: (() => void) | null;
    doLogout: (() => void) | null;
}

export const AuthContext: React.Context<IAuthContext> = createContext<IAuthContext>(
    {
        user: null,
        authStatus: {
            loggedIn: false,
            authComplete: false,
        },
        doLogin: null,
        doLogout: null
    }
)

interface Props {
    children: ReactNode
}

const Authenticate = ({children}: Props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const ws = useRef<WebSocket | null>(null);
    const {showAlert} = useContext(GlobalAlertContext)

    const [user, setUser] = useState<IClientUser | null>(null);
    const [authStatus, setAuthStatus] = useState({
        loggedIn: false,
        authComplete: false,
    });


    const doLogout = () => {
        setUser(null);
        tokenManager.deleteToken();
        setAuthStatus({loggedIn: false, authComplete: true});
        navigate('/zenauth/login');
    }

    const doLogin = async () => {

        // Always refresh the token - mainly to retrieve subscription status
        await tokenManager.getToken(true);
        const userData: IClientUser | null = tokenManager.getUser();

        setAuthStatus({loggedIn: true, authComplete: true});
        setUser(userData);

        if (nonAuthenticatedRoutes.includes(location.pathname)) {

            navigate('/');

        }

    }


    // Handle authentication check in useEffect
    useEffect(() => {


        // User has browsed to login / register page
        if (nonAuthenticatedRoutes.includes(location.pathname)) {

            return setAuthStatus({loggedIn: false, authComplete: true});

        }

        tokenManager.getToken(true).then(() => {

            // Authenticated route. Check token and refresh if necessary
            const tokenValid = tokenManager.tokenValid();

            if (!tokenValid) {

                setAuthStatus({loggedIn: false, authComplete: true});
                setUser(null);
                navigate('/zenauth/login');
                return;
            }

            const userData: IClientUser | null = tokenManager.getUser();
            setUser(userData);
            setAuthStatus({loggedIn: true, authComplete: true});

        }).catch(
            (err: any) => {
                console.error(err);

                setAuthStatus({loggedIn: false, authComplete: true});
                setUser(null);
                navigate('/zenauth/login');
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
            }
        );



    }, [location.pathname]);

    if(!authStatus.authComplete) return null;

    return <AuthContext.Provider value={{user, authStatus, doLogout, doLogin}}>{children}</AuthContext.Provider>;

}

export default Authenticate
