import React, {useContext, useEffect, useState} from 'react'
import {IZenGoal, IZenTask} from '@yellowmelon/zen-global-types'
import ApiClient from '../../lib/ApiClient'
import {EAlertType, GlobalAlertContext} from '../../components/GlobalAlert'

const HomePage = () => {
    const [tasks, setTasks] = useState<IZenTask[]>([]);
    const [goals, setGoals] = useState<IZenGoal[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const apiClient = new ApiClient();
    const { showAlert } = useContext(GlobalAlertContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const goalsData = await apiClient.get<IZenGoal[]>('api/v1/goals');
                setGoals(goalsData);

                // Assuming similar endpoint structure for tasks
                const tasksData = await apiClient.get<IZenTask[]>('api/v1/tasks?todayonly=true');
                setTasks(tasksData);
            } catch (err: any) {
                console.error(err);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='page-content home-page p-2'>
            <h1 className='text-xl font-bold mb-4'>Welcome Back</h1>

            <div className='flex flex-col lg:flex-row gap-4'>
                <section className='flex-1'>
                    <h2 className='text-lg font-semibold mb-3'>Today's Tasks</h2>
                    <div className='border rounded-lg p-4'>
                        {isLoading ? (
                            <div className='text-gray-500'>Loading tasks...</div>
                        ) : tasks.length === 0 ? (
                            <div className='text-gray-500'>No tasks for today</div>
                        ) : (
                            <div className='space-y-2 max-h-[400px] overflow-y-auto'>
                                {tasks.map(task => (
                                    <div key={task._id?.toString()} className='p-2 border rounded'>
                                        {task.title}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </section>

                <section className='flex-1'>
                    <h2 className='text-lg font-semibold mb-3'>Current Goals</h2>
                    <div className='border rounded-lg p-4'>
                        {isLoading ? (
                            <div className='text-gray-500'>Loading goals...</div>
                        ) : goals.length === 0 ? (
                            <div className='text-gray-500'>No active goals</div>
                        ) : (
                            <div className='space-y-2 max-h-[400px] overflow-y-auto'>
                                {goals.map(goal => (
                                    <a
                                        href={`/goal/${goal._id}/overview`}
                                        key={goal._id?.toString()}
                                        className='p-2 border rounded block hover:bg-gray-50 transition-colors'
                                    >
                                        {goal.title}
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </div>
    )
}

export default HomePage
