import {IClientUser} from "../components/Authenticate";
import jwt_decode from "jwt-decode";
import {IApiSuccessResponse} from "./ApiClient";
import axios from "axios";
import config from "../../config/config";
import {ELLMRequestStatus, IUser} from "@yellowmelon/zen-global-types";

interface IConstructor {
    storageKey?: string;
}

export class ManageBearerToken {

    storageKey: string;
    token: string;

    constructor({storageKey = 'zen_token'}: IConstructor = {}) {

        this.storageKey = storageKey;
        this.token = '';
    }

    setToken(token: string) {

        localStorage.setItem(this.storageKey, token)

    }

    async getToken(forceRefresh = false) {

        try {

            const token = localStorage.getItem(this.storageKey);

            if (!token) return null;

            const {exp}: { data: any, exp: number, iat: number } = jwt_decode(token);

            const currentTime: number = Math.floor(new Date().getTime() / 1000);

            if (currentTime < exp) {
                return token;
            }

            if (!forceRefresh) {
                return null;
            }

            // Token expired, refresh it on the server.
            const headerObject = {
                headers: {Authorization: `Bearer ${token}`}
            }

            const response = await axios.post<IApiSuccessResponse<{
                token: string
            }>>(`${config.apiServer}/auth/v1/tokenops/refresh`, {}, headerObject);

            const newToken = response.data.token

            if (!newToken) throw new Error("Could not refresh token");

            this.setToken(newToken);

            return newToken;

        } catch (err: any) {

            this.deleteToken();
            console.error(`Error getting token: ${err.message}`);
            throw new Error(`Error getting token: ${err.message}`);

        }

    }

    getUser(): IClientUser | null {

        const token = localStorage.getItem(this.storageKey);

        if (!token) return null

        const decoded: any = jwt_decode(token);

        const clientUser: IClientUser = {
            ...decoded?.data?.user as IUser,
            llmStatus: decoded?.data?.llmStatus as ELLMRequestStatus,
            freeTrialExpires: decoded?.data?.freeTrialExpires as Date
        };

        return clientUser;

    }

    tokenValid() {
        const token = localStorage.getItem(this.storageKey);
        if (!token) return false;

        try {
            const decoded: any = jwt_decode(token);
            const expiry: number | null = decoded?.exp;

            if (!expiry) return false;

            // Compare expiry with current time in seconds
            const currentTime = Math.floor(Date.now() / 1000);
            const tokenValid = currentTime < expiry;

            if (!tokenValid) {
                this.deleteToken();
                return false;
            }

            return true;

        } catch (err: any) {
            console.error(`Error processing token: ${err}`);
            this.deleteToken(); // Delete token on error
            return false;
        }
    }

    deleteToken() {
        localStorage.removeItem(this.storageKey)
    }

}
