import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import KanbanCard from "./KanbanCard";
import AddTaskModal from "./AddTaskModal"
import {ETaskStatus, IZenTask} from "@yellowmelon/zen-global-types";
import {EAlertType, GlobalAlertContext} from "../../../../components/GlobalAlert";
import ApiClient from "../../../../lib/ApiClient";
import KanbanColumnMenu from "./KanbanColumnMenu";
import Tooltip from "../../../../components/Tooltip";
import ZenToggle from "../../../../components/ZenToggle";
import {GlobalContext} from "../../../../lib/GlobalContextProvider";


interface Props {
    title: string
    status: ETaskStatus
    setTaskList: Dispatch<SetStateAction<IZenTask[]>>
    taskList: IZenTask[],
    showAddButton?: boolean,
}

const apiClient = new ApiClient();

const KanbanColumn = ({title, status, taskList, setTaskList, showAddButton = true}: Props) => {

    const [editTask, setEditTask] = useState<IZenTask | null>(null)
    const [columnList, setColumnList] = useState<IZenTask[]>(taskList);

    const {showAlert} = useContext(GlobalAlertContext);
    const {filterToday, updateFilterToday} = useContext(GlobalContext);

    const columnRef = useRef(null);
    const tasklistRef = useRef(null);

    useEffect(
        () => {

            setColumnList(taskList.filter((task: IZenTask) => task.status === status))

        }, [taskList]
    )

    // Move a card up and down in the list, as well as across to other columns
    const moveItem = (item: IZenTask, dragIndex: number, hoverIndex: number) => {

        const nonStatusList: IZenTask[] = taskList.filter((goal: IZenTask) => (goal.status !== status && goal._id !== item._id));
        let moveToList: IZenTask[] = columnList.filter((step: IZenTask) => step._id !== item._id);

        moveToList.splice(hoverIndex, 0, {...item, status});
        moveToList = moveToList.map((goal: IZenTask, index: number) => ({...goal, index}));

        const combinedList = [...nonStatusList, ...moveToList];
        setTaskList(combinedList);

    }

    const onDrop = async (oldTask: IZenTask) => {

        const updatedTask = taskList.find((goal: IZenTask) => goal._id === oldTask._id)

        if (!updatedTask) {

            return showAlert(EAlertType.danger, `Sorry but an error occurred while saving this task: could not find updated task`);

        }

        try {

            await apiClient.put<IZenTask>(`api/v1/tasks/${updatedTask._id}`, {
                payload: {
                    task: updatedTask,
                    newIndex: updatedTask.index
                }
            })

        } catch (err: any) {
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
        }

    }


    return (
        <div
            ref={columnRef}
            key='kanban-column'
            className='kanban-column border-gray-800 min-w-full [&:not(:last-child)]:mr-2 md:min-w-[49%] md:[&:not(:last-child)]:mr-[1%] lg:min-w-[24%] lg:[&:not(:last-child)]:mr-[1%] snap-center' >

            <div
                className="flex flex-col border border-gray-200 rounded-xl p-4 dark:border-gray-700 bg-gray-50 dark:bg-slate-900">

                <div className={'flex items-center mb-4'}>

                    <h4 className="font-bold text-lg text-gray-800 dark:text-gray-200 flex align-middle w-full">
                        {title} {
                            title.toLowerCase() === 'today' ?
                                (<div className='flex align-middle ml-auto mr-4'>
                                    <Tooltip
                                        className='mr-1'
                                        position='bottom'
                                        toolTip={`Today column shows tasks from all contexts by default, select 'context only to filter on current context`}
                                        iconClasses='bg-black text-white'/>
                                    <ZenToggle
                                        checked={filterToday}
                                        onChange={ (checked: boolean) => { updateFilterToday(checked) } }
                                        labelRight='context only'
                                    />
                                </div>) : ''
                        }
                    </h4>

                    <KanbanColumnMenu
                        columnStatus={status}
                        className={'ml-auto'}
                        columnTasks={columnList}
                    />

                </div>

                <div ref={tasklistRef} className='task-list mb-4 max-h-[650px] overflow-y-auto overflow-x-hidden'>

                    {
                        // Insert a dummy card into the list so that tasks from other columns can be dropped on it
                        !columnList.length && <KanbanCard
                            columnRef={columnRef}
                            tasklistRef={tasklistRef}
                            moveItem={moveItem}
                            key={0}
                            index={0}
                            onDrop={onDrop}
                            task={null}/>

                    }

                    {
                        !!columnList.length &&
                        columnList.sort((a: IZenTask, b: IZenTask) => a.index - b.index).map(
                            (task: IZenTask, index: number) => <KanbanCard
                                columnRef={columnRef}
                                tasklistRef={tasklistRef}
                                moveItem={moveItem}
                                key={index}
                                index={index}
                                onDrop={onDrop}
                                onClick={
                                    (task) => {
                                        setEditTask(task);
                                    }
                                }

                                task={task}/>
                        )
                    }

                </div>

                { showAddButton &&

                    <AddTaskModal
                        key='add-step-modal'
                        editTask={editTask}
                        setEditTask={setEditTask}
                        status={status}
                    />

                }


            </div>

        </div>
    )


}


export default KanbanColumn
