import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    EActiveStatus,
    ETaskPriority,
    ETaskStatus,
    EZenObjectiveSuggestionStatus,
    IZenContext,
    IZenGoal,
    IZenObjectiveSuggestionModel,
    IZenTask
} from "@yellowmelon/zen-global-types";
import {EAlertType, GlobalAlertContext} from "../../components/GlobalAlert";
import ApiClient from "../../lib/ApiClient";
import ZenButton from "../../components/ZenButton";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import GoalResourceCard from "./GoalResources/GoalResourceCard";
import GoalSuggestionCard from "./GoalSuggestions/GoalSuggestionCard";
import AddStagedTaskModal from './AddStagedTaskModal';
import Tooltip from "../../components/Tooltip";
import ManageContext from "../Kanban/KanbanBoard/ManageContext";

const GoalPage = () => {
    const apiClient = new ApiClient();
    const {goalid} = useParams();
    const navigate = useNavigate();
    const {showAlert} = useContext(GlobalAlertContext);
    const [goal, setGoal] = useState<IZenGoal | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingResources, setLoadingResources] = useState<boolean>(false);
    const [loadingTasks, setLoadingTasks] = useState<boolean>(false);
    const [editTask, setEditTask] = useState<IZenTask | null>(null);

    useEffect(() => {
        getGoal(false).then((retrievedGoal: IZenGoal | undefined) => {
            setGoal(retrievedGoal);

            // Automatically populate if suggestions, resources, or taskList are empty
            if (retrievedGoal) {
                if (!retrievedGoal.suggestions || retrievedGoal.suggestions.length === 0) {
                    getMoreSuggestions(retrievedGoal._id?.toString()).then( );
                }
                if (!retrievedGoal.resources || retrievedGoal.resources.length === 0) {
                    getMoreResources(retrievedGoal._id?.toString()).then( );
                }
                if (!retrievedGoal.taskList || retrievedGoal.taskList.length === 0) {
                    getMoreTasks(retrievedGoal._id?.toString()).then( );
                }
            }
        });

    }, []);

    const getGoal = async (saveGoal = true) => {

        try {

            const retrievedGoal = await apiClient.get<IZenGoal>(`api/v1/goals/${goalid}?childtasks=true`);
            !!saveGoal && setGoal(retrievedGoal);

            return retrievedGoal;

        } catch (err: any) {
            console.error(err)
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
        }

    }

    const updateGoal = async (updatedGoal: IZenGoal, saveGoal = true) => {

        // The goal may have been saved in a child component, in this case save the state
        // for other child components
        if (!saveGoal) {
            return setGoal(updatedGoal);
        }

        try {

            const zenGoal: IZenGoal = await apiClient.put<IZenGoal>(`api/v1/goals/${updatedGoal?._id}?childtasks=true`, {payload: {goal: updatedGoal}});
            setGoal(zenGoal);

        } catch (err: any) {
            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err}`);
        }


    }

    const deleteGoal = () => {

        if (!confirm('This goal, suggestions and task list will be deleted. Child tasks will not be deleted. Proceed?')) return;

        apiClient.delete(`api/v1/goals/${goalid}`).then(
            (response) => {

                navigate('/goals');

            }
        ).catch(
            (err: any) => {

                console.error(`Error deleting goal: ${err.message}`);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    const getMoreSuggestions = async (goalId?: string) => {
        setLoading(true);
        const getmore = !!goal?.suggestions?.length;
        const targetGoalId = goalId || goal?._id;

        try {
            const updatedGoal = await apiClient.put<IZenGoal>(`api/v1/goals/suggestions/${targetGoalId}${getmore ? '?getmore=true' : ''}`);
            setLoading(false);
            setGoal((prevGoal) => updatedGoal);
        } catch (err: any) {
            setLoading(false);
            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
        }
    };

    const getMoreResources = async (goalId?: string) => {
        setLoadingResources(true);
        const getmore = !!goal?.resources?.length;
        const targetGoalId = goalId || goal?._id;

        try {
            const updatedGoal = await apiClient.put<IZenGoal>(`api/v1/goals/resources/${targetGoalId}${getmore ? '?getmore=true' : ''}`);
            setLoadingResources(false);
            setGoal((prevGoal) => updatedGoal);
        } catch (err: any) {
            setLoadingResources(false);
            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
        }
    };

    const getMoreTasks = async (goalId?: string) => {
        setLoadingTasks(true);
        const targetGoalId = goalId || goal?._id;

        try {
            const updatedGoal = await apiClient.put<IZenGoal>(`api/v1/goals/gettasklist/${targetGoalId}?getmore=true`);
            setLoadingTasks(false);
            setGoal((prevGoal) => updatedGoal);
        } catch (err: any) {
            setLoadingTasks(false);
            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
        }
    };

    const addTaskToStaged = async (taskToAdd: any) => {
        // Create updated goal object
        const updatedGoal = {
            ...goal!,
            stagedTaskList: [
                ...(goal!.stagedTaskList || []),
                {
                    ...taskToAdd,
                    index: ((goal!.stagedTaskList || []).length + 1)
                }
            ]
        };

        // Remove the item from either suggestions or taskList
        if (goal?.suggestions?.some(s => s._id === taskToAdd._id)) {
            updatedGoal.suggestions = goal.suggestions.filter(s => s._id !== taskToAdd._id);
        } else if (goal?.taskList?.some(t => t._id === taskToAdd._id)) {
            updatedGoal.taskList = goal.taskList.filter(t => t._id !== taskToAdd._id);
        }

        await updateGoal(updatedGoal);
    };

    const createTaskFromSuggestion = (suggestion: IZenObjectiveSuggestionModel) => {

        if (!confirm('This will create a new task in your kanban board from this suggestion. Proceed?')) return;

        const newTask: IZenTask = {
            title: suggestion.method,
            description: suggestion.description,
            priority: ETaskPriority.normal,
            status: ETaskStatus.inbox,
            context: goal?.context ? goal.context : null,
            checklist: [],
            index: 0,
            suggestions: [],
            taskList: [],
            activeStatus: EActiveStatus.active,
            notes: [],
            resources: []
        }

        apiClient.post<IZenTask>(`api/v1/tasks`,
            {
                payload: {task: newTask}
            }
        ).then(
            async (createdTask: IZenTask) => {

                if (!goal?.childTasks || !createdTask._id) return;

                const childTaskList = goal.childTasks.map((childTask) => {

                    if ((childTask as IZenTask)._id === undefined) return childTask.toString();
                    return (childTask as IZenTask)._id;

                })

                // Keep a list of child tasks in the parent goal
                const goalWithTasks: IZenGoal = await apiClient.put<IZenGoal>(`api/v1/goals/${goal?._id}?childtasks=true`,
                    {
                        payload: {
                            goal: {
                                ...goal,
                                childTasks: [...childTaskList, createdTask._id.toString()]
                            } as IZenTask
                        }
                    })

                // Now update the status of the suggestion
                const updatedGoal: IZenGoal = await apiClient.put<IZenGoal>(`api/v1/goals/suggestions/${goal._id}/${suggestion._id}`,
                    {payload: {suggestion: {...suggestion, taskCreated: true} as IZenObjectiveSuggestionModel}})

                updateGoal({
                    ...goal,
                    childTasks: goalWithTasks.childTasks,
                    suggestions: updatedGoal.suggestions
                }, false);

                showAlert(
                    EAlertType.info,
                    `This suggestion has been turned into a task which is now available to be managed in your kanban board`
                )

            }
        ).catch(
            (err: any) => {

                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    const createGoalFromSuggestion = async (suggestion: IZenObjectiveSuggestionModel) => {

        if (!confirm('This will create a new top level goal from this suggestion. Proceed?')) return;

        try {

            const newGoal: IZenGoal = {
                title: suggestion.method,
                description: suggestion.description,
                context: goal?.context ? goal.context : null,
                index: 0,
                suggestions: [],
                taskList: [],
                activeStatus: EActiveStatus.active,
                notes: [],
                resources: []
            }

            const createdGoal: IZenGoal = await apiClient.post<IZenGoal>(`api/v1/goals`,{payload: {goal: newGoal}});

            showAlert(EAlertType.success, 'A new goal has been created, you will be able to view it on the top level goals page');


        } catch (err: any){

            console.error(err.message);
            showAlert(EAlertType.danger, `Sorry but an error occurred whilst creating this goal: ${err.message}`);

        }

    }

    const addTaskToKanban = (suggestedTask: IZenObjectiveSuggestionModel) => {

        if(!confirm('This will create a new task in your kanban board from this suggestion. Proceed?')) return;

        const newTask: IZenTask = {
            title: suggestedTask.method,
            description: suggestedTask.description,
            priority: ETaskPriority.normal,
            status: ETaskStatus.inbox,
            context: goal?.context ? goal.context : null,
            parentGoal: goal?._id,
            checklist: [],
            index: 0,
            suggestions: [],
            notes: [],
            resources: [],
            taskList: [],
            activeStatus: EActiveStatus.active
        }

        apiClient.post<IZenTask>(`api/v1/tasks`,
            {
                payload: {task: newTask}
            }
        ).then(
            async (childTask: IZenTask)=>{
                const updatedSuggestion:IZenObjectiveSuggestionModel = {...suggestedTask, status: EZenObjectiveSuggestionStatus.deleted}

                // Remove task from staged task list
                const updatedGoalData = {
                    ...goal,
                    childTasks: [...goal?.childTasks ? goal.childTasks : [], childTask._id],
                    stagedTaskList: goal?.stagedTaskList?.filter(task => task._id !== suggestedTask._id) || []
                } as IZenGoal;

                // Keep a list of child tasks in the goal and update staged task list
                const updatedGoal = await apiClient.put<IZenGoal>(`api/v1/goals/${goal?._id}`,
                    {payload: { goal: updatedGoalData }})

                getGoal();


            }
        ).catch(
            (err: any) => {
                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
            }
        )
    }

    const deleteStagedTask = async (taskId: string) => {
        if (!goal || !confirm('Are you sure you want to remove this task from the staged list?')) return;

        try {
            const updatedGoal = {
                ...goal,
                stagedTaskList: goal.stagedTaskList?.filter(task => task._id !== taskId) || []
            };

            await updateGoal(updatedGoal);
            showAlert(EAlertType.success, 'Task removed from staged list');
        } catch (err: any) {
            console.error(err);
            showAlert(EAlertType.danger, `Failed to remove task: ${err.message}`);
        }
    };

    const deleteTaskFromList = async (taskId: string) => {
        if (!goal || !confirm('Are you sure you want to remove this task from the list?')) return;

        try {
            const updatedGoal = {
                ...goal,
                taskList: goal.taskList?.filter(task => task._id !== taskId) || []
            };

            await updateGoal(updatedGoal);
            showAlert(EAlertType.success, 'Task removed from list');
        } catch (err: any) {
            console.error(err);
            showAlert(EAlertType.danger, `Failed to remove task: ${err.message}`);
        }
    };

    const deleteSuggestion = async (suggestionId: string) => {
        if (!goal || !confirm('Are you sure you want to remove this suggestion?')) return;

        try {
            const updatedGoal = {
                ...goal,
                suggestions: goal.suggestions?.filter(suggestion => suggestion._id !== suggestionId) || []
            };

            await updateGoal(updatedGoal);
            showAlert(EAlertType.success, 'Suggestion removed successfully');
        } catch (err: any) {
            console.error(err);
            showAlert(EAlertType.danger, `Failed to remove suggestion: ${err.message}`);
        }
    };

    const handleContextChange = async (contextId: string | null) => {
        if (!goal) return;

        try {
            const updatedGoal = {
                ...goal,
                context: contextId
            };
            await updateGoal(updatedGoal);
            showAlert(EAlertType.success, 'Context updated successfully');
        } catch (err: any) {
            console.error(err);
            showAlert(EAlertType.danger, `Failed to update context: ${err.message}`);
        }
    };

    return (
        <div className='page-content analyse-goal pt-4 pb-2'>
            <div className='flex flex-col gap-2 mb-5'>
                <h1 className={'font-medium'}>
                    {goal?.title}
                </h1>
                <div className={'flex justify-between items-center mb-4'}>
                    <ManageContext
                        parentContext={goal?.context?.toString()}
                        updateParentContext={handleContextChange}
                    />

                    <div className='flex gap-2'>
                        <ZenButton
                            type={'button'}
                            zenType={'danger'}
                            label={'Delete Goal'}
                            onClick={deleteGoal}/>

                        <ZenButton
                            type={'button'}
                            icon={faArrowLeft}
                            linkTo={'/goals'}
                            zenType={'default'}
                            label={'Back to top level goals'}/>
                    </div>
                </div>
            </div>

            {goal && (
                <div className='flex flex-col gap-4'>
                    {/* Top Row - Action Plan, Task List, and Suggestions */}
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                        {/* Action Plan */}
                        <div className='bg-white rounded-lg shadow p-4 flex flex-col border border-gray-200'>
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className='text-xl font-semibold'>
                                    Action Plan
                                    <Tooltip
                                        toolTip={'A list of tasks - a plan - to achieve your goal. Add these tasks directly to your kanban board'}
                                        iconClasses={'bg-black !py-[1px] !px-[8px] ml-2 text-white text-lg'}
                                    />
                                </h2>
                                <AddStagedTaskModal
                                    onSave={(newTask) => {
                                        if (!goal) return;
                                        const updatedGoal = {
                                            ...goal,
                                            stagedTaskList: [
                                                ...(goal.stagedTaskList || []),
                                                {
                                                    ...newTask,
                                                    index: ((goal.stagedTaskList || []).length + 1)
                                                }
                                            ]
                                        };
                                        updateGoal(updatedGoal);
                                    }}
                                />
                            </div>
                            <div className='overflow-y-auto h-[400px]'>
                                {/* Staged Tasks */}
                                {(goal.stagedTaskList && goal.stagedTaskList.length > 0) && (
                                    <>
                                        <h3 className='text-lg font-medium mb-2'>Staged Tasks</h3>
                                        {goal.stagedTaskList.map((task) => (
                                            <GoalSuggestionCard
                                                key={task._id?.toString()}
                                                item={task}
                                                createTask={() => { addTaskToKanban(task) }}
                                                taskButtonLabel={'Send task to kanban board'}
                                                deleteItem={() => task._id && deleteStagedTask(task._id.toString())}
                                            />
                                        ))}
                                    </>
                                )}

                                {/* Child Tasks */}
                                {(goal.childTasks && goal.childTasks.length > 0) && (
                                    <>
                                        <h3 className='text-lg font-medium mb-2 mt-4'>Active Tasks</h3>
                                        {goal.childTasks.map((task) => (
                                            <GoalSuggestionCard
                                                key={typeof task === 'string' ? task : (task as IZenTask)._id?.toString()}
                                                item={task as IZenTask}
                                                taskButtonLabel={'View task'}
                                                createTask={() => {
                                                    const taskId = typeof task === 'string' ? task : (task as IZenTask)._id;
                                                    navigate(`/tasks/${taskId}`);
                                                }}
                                                deleteItem={() => {
                                                    console.log('Remove child task:', task);
                                                }}
                                            />
                                        ))}
                                    </>
                                )}

                                {(!goal.stagedTaskList?.length && !goal.childTasks?.length) && (
                                    <p className="text-gray-500 italic">No tasks available</p>
                                )}
                            </div>
                        </div>

                        {/* Suggested Task List */}
                        <div className='bg-white rounded-lg shadow p-4 flex flex-col border border-gray-200'>
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className='text-xl font-semibold'>
                                    Suggested Task List
                                    <Tooltip
                                        toolTip={'An AI generated set of steps to achieve your goal. Add the ones you like to your action plan'}
                                        iconClasses={'bg-black !py-[1px] !px-[8px] ml-2 text-white text-lg'}
                                    />
                                </h2>
                                <ZenButton
                                    label={loadingTasks ? "Loading..." : "Get More"}
                                    zenType="primary"
                                    size="sm"
                                    onClick={() => getMoreTasks()}
                                    disabled={loadingTasks}
                                />
                            </div>
                            <div className='overflow-y-auto h-[400px]'>
                                {goal.taskList && goal.taskList.length > 0 ? (
                                    <div className='flex flex-col gap-2'>
                                        {goal.taskList.map((task) => (
                                            <GoalSuggestionCard
                                                key={task._id?.toString()}
                                                item={task}
                                                createTask={() => addTaskToStaged(task)}
                                                createGoal={(id) => {
                                                    createGoalFromSuggestion(task).then( );
                                                }}
                                                deleteItem={() => task._id && deleteTaskFromList(task._id.toString())}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-gray-500 italic">No tasks available</p>
                                )}
                            </div>
                        </div>

                        {/* Suggestions Section */}
                        <div className='bg-white rounded-lg shadow p-4 flex flex-col border border-gray-200'>
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className='text-xl font-semibold'>
                                    Suggestions
                                    <Tooltip
                                        toolTip={'An AI generated list of suggestions to achieve your goal. Add the ones you like to your action plan'}
                                        iconClasses={'bg-black !py-[1px] !px-[8px] ml-2 text-white text-lg'}
                                    />
                                </h2>
                                <ZenButton
                                    label={loading ? "Loading..." : "Get More"}
                                    zenType="primary"
                                    size="sm"
                                    onClick={() => getMoreSuggestions()}
                                    disabled={loading}
                                />
                            </div>
                            {goal.suggestions && goal.suggestions.length > 0 ? (
                                <div className='overflow-y-auto h-[400px]'>
                                    {goal.suggestions.map((suggestion) => (
                                        <GoalSuggestionCard
                                            key={suggestion._id?.toString() || ''}
                                            item={suggestion}
                                            createTask={() => addTaskToStaged(suggestion)}
                                            createGoal={(id) => {
                                                createGoalFromSuggestion(suggestion).then()
                                            }}
                                            deleteItem={() => suggestion._id && deleteSuggestion(suggestion._id.toString())}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <p className="text-gray-500 italic">No suggestions available</p>
                            )}
                        </div>
                    </div>

                    {/* Bottom Row - Resources and Notes */}
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        {/* Resources Section */}
                        <div className='bg-white rounded-lg shadow p-4 flex flex-col border border-gray-200'>
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className='text-xl font-semibold'>Resources</h2>
                                <ZenButton
                                    label={loadingResources ? "Loading..." : "Get More"}
                                    zenType="primary"
                                    size="sm"
                                    onClick={() => getMoreResources()}
                                    disabled={loadingResources}
                                />
                            </div>
                            <div className='overflow-y-auto h-[400px]'>
                                <div className='grid grid-cols-1 gap-4'>
                                    {goal.resources && goal.resources.length > 0 ? (
                                        goal.resources.map((resource) => (
                                            <GoalResourceCard
                                                key={resource._id?.toString()}
                                                resource={resource}
                                            />
                                        ))
                                    ) : (
                                        <p className="text-gray-500 italic">No resources available</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Notes Section */}
                        <div className='bg-white rounded-lg shadow p-4 flex flex-col border border-gray-200'>
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className='text-xl font-semibold'>Notes</h2>
                            </div>
                            <div className='overflow-y-auto h-[400px]'>
                                {goal.notes && goal.notes.length > 0 ? (
                                    <div className='prose max-w-none'>
                                        {goal.notes.map((note, index) => (
                                            <div key={note._id?.toString() || index}>
                                                {note.content}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-gray-500 italic">No notes available</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GoalPage;
