import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import GoalCard from "./GoalCard";
import ApiClient from "../../lib/ApiClient";
import {EAlertType, GlobalAlertContext} from "../../components/GlobalAlert";
import {IZenGoal} from "@yellowmelon/zen-global-types";

const apiClient = new ApiClient();

const TopGoals = () => {

    const [goalTitle, setGoalTitle] = useState<string>('');
    const [zenGoals, setZenGoals] = useState<IZenGoal[]>([]);
    const [goalsToShow, setGoalsToShow] = useState<IZenGoal[]>([]);
    const [goalCreated, setGoalCreated] = useState<boolean>(false);
    const [showAllGoals, setShowAllGoals] = useState<boolean>(() => {
        const stored = localStorage.getItem('showAllGoals');
        return stored ? JSON.parse(stored) : false;
    });
    const navigate = useNavigate();
    const {showAlert} = useContext(GlobalAlertContext);

    const getGoals = ()=>{

        apiClient.get<IZenGoal[]>(`api/v1/goals`).then(
            (retrievedGoals)=>{

                setZenGoals(retrievedGoals);

            }
        ).catch(
            (err)=>{

                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`)

            }
        )

    }


    useEffect(
        ()=>{

            getGoals()

        },[]
    )

    useEffect(
        ()=>{

            if (showAllGoals) {
                setGoalsToShow(zenGoals);
            } else {
                setGoalsToShow(zenGoals.slice(0,8));
            }

        },[showAllGoals, zenGoals]
    )

    useEffect(() => {
        localStorage.setItem('showAllGoals', JSON.stringify(showAllGoals));
    }, [showAllGoals]);

    const addGoal = () => {

        apiClient.post<IZenGoal>(`api/v1/goals`, {payload: {goal: {title: goalTitle}}}).then(
            (createdGoal)=>{

                setGoalTitle('');
                setGoalCreated(true);

                setTimeout(
                    ()=>{
                        setGoalCreated(false);
                        navigate(`/goal/${createdGoal._id}/overview`)

                    },1000
                )

            }
        ).catch(
            (err)=>{

                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`)

            }
        )

    }

    return (
        <div className='page-content home-page p-2'>

            <div className='max-w-screen-md mx-auto mb-4 md:mb-10'>

                <h2 className={'py-4'}>
                    Break down, track and manage your goals using artificial intelligence
                </h2>

                <form className='generic-form py-2' onSubmit={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    addGoal();
                }}>

                    <input type="text"
                           value={goalTitle}
                           onChange={(ev) => {
                               setGoalTitle(ev.target.value)
                           }}
                           className="text-input"
                           placeholder="Enter a high level goal: 'make a million dollars', 'start a business', 'learn French', 'make more friends'"/>

                    <div className='flex items-center justify-end'>

                        { goalCreated && <span className='bg-green-700 inline-block py-1 px-3 text-white rounded text-sm mr-2'>Goal created</span>}

                        <button type="submit"
                                className="py-2 px-3 inline-flex justify-center float-right items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
                            Smash my goal
                        </button>

                    </div>

                    <div className='clear-both'></div>

                </form>


            </div>

            { !!zenGoals.length &&

                <div>

                    <h2 className={'pb-4 text-center'}>Top level goals</h2>

                    <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4 card-container'}>

                        {
                            goalsToShow.map((goal: IZenGoal) => <GoalCard key={goal._id?.toString()} goal={goal}/>)
                        }

                    </div>

                    {zenGoals.length > 8 && (
                        <>
                            <hr className="border-t border-gray-200 my-4" />
                            <div className="flex justify-center items-center mt-4">
                                <p className="mr-4">
                                    {showAllGoals ? 'Showing all goals' : 'Only showing your most recent goals'}
                                </p>
                                <button
                                    onClick={() => setShowAllGoals(!showAllGoals)}
                                    className="py-2 px-3 rounded-md border border-gray-300 font-semibold bg-transparent text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all text-sm"
                                >
                                    {showAllGoals ? 'Show only most recent' : 'Show all'}
                                </button>
                            </div>
                        </>
                    )}

                </div>

            }

        </div>
    )

}

export default TopGoals;
