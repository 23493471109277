import React from "react";

interface Props {
    checked: boolean
    onChange: (checked: boolean) => void
    labelRight?: string
    labelLeft?: string
}

const ZenToggle = ({checked, onChange, labelLeft = '', labelRight = ''}: Props) => {

    const i = 0;

    return (
        <div className='zen-toggle flex items-center'>

            { !!labelLeft && <label className="mr-1 text-sm font-medium text-gray-900 dark:text-gray-300">{ labelLeft }</label>}

            <div className={`relative w-[3.25rem] h-6 bg-gray-200 rounded-full cursor-pointer flex items-center dark:bg-gray-800 ${checked ? 'bg-blue-300' : ''} `}
                 onClick={(ev: any)=>{ onChange(ev.target.checked) }}>
                <input
                    type="checkbox"
                    className="opacity-0 absolute h-full w-full"
                    checked={checked} onChange={(ev: any)=>{ onChange(ev.target.checked) }}
                />
                <span className={`absolute left-1 bg-white w-5 h-5 rounded-full transition-transform duration-200 ease-in-out ${checked ? 'translate-x-[1.625rem]' : 'translate-x-0'} dark:bg-gray-400`} />

            </div>

            { labelRight && <label className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-300">{ labelRight }</label>}


        </div>
)
}

export default ZenToggle
