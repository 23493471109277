import React, {useContext, useEffect, useState} from "react";
import ZenButton from "../../../../components/ZenButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {IZenContext} from "@yellowmelon/zen-global-types";
import ZenDropdown from "../../../../components/ZenDropdown";
import {IKanbanFilters} from "../index";
import ApiClient from "../../../../lib/ApiClient";
import {EAlertType, GlobalAlertContext} from "../../../../components/GlobalAlert";

interface Props {
    className?: string;
    filters: IKanbanFilters;
    setFilters: (filters: IKanbanFilters) => void;
    contexts: IZenContext[];
}

interface IGoalResponse {
    _id: string;
    title: string;
}

const apiClient = new ApiClient();

const FilterKanbanTasks = ({
    className = '',
    filters,
    setFilters,
    contexts
}: Props) => {

    const [goals, setGoals] = useState<IGoalResponse[]>([]);
    const { showAlert } = useContext(GlobalAlertContext);

    useEffect(() => {

        // Get a list of goals for the goal chooser
        const fetchGoals = async () => {
            try {
                const response = await apiClient.get<IGoalResponse[]>('api/v1/goals/list');
                setGoals(response);
            } catch (error: any) {
                console.error('Error fetching goals:', error);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${error.message}`);
            }
        };

        fetchGoals().then();

    }, []);

    return (
        <div className={`filter-kanban-tasks ${!!className && className} flex items-center gap-2`}>

            <ZenButton
                label={
                    <span className="flex items-center">
                        {filters.filterPriority && <FontAwesomeIcon icon={faCheck} className="mr-2" />}
                        {filters.filterPriority ? 'Showing Only Priority Tasks' : 'Show Only Priority Tasks'}
                    </span>
                }
                zenType="danger"
                size='sm'
                onClick={() => {
                    setFilters({
                        ...filters,
                        filterPriority: !filters.filterPriority
                    });
                }}
                className={`
                    transition-all duration-300
                    ${filters.filterPriority ? 'bg-red-400 hover:bg-red-500' : 'bg-red-600 hover:bg-red-700'}
                `}
            />
            <div className="flex gap-2 items-center">

                <span className="text-gray-600">Filter by context</span>

                <ZenDropdown<IZenContext>
                    selectNoneOption
                    selectedItem={filters.contextId}
                    items={contexts}
                    onClick={(index) => {
                        const newContext = index === null ? null : contexts[index];
                        setFilters({
                            ...filters,
                            contextId: newContext?._id?.toString() || null
                        });
                    }}
                />

                <span className="text-gray-600">Filter by goal</span>

                <ZenDropdown<IGoalResponse & {name: string}>
                    selectNoneOption
                    selectedItem={filters.goalId}
                    items={goals.map(goal => ({ ...goal, name: goal.title }))}
                    onClick={(index) => {
                        const newGoal = index === null ? null : goals[index];
                        setFilters({
                            ...filters,
                            goalId: newGoal?._id?.toString() || null
                        });
                    }}
                />

            </div>
        </div>
    );
};

export default FilterKanbanTasks;
