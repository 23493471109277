import React, {useContext, useEffect, useState} from "react";
import Tooltip from "../../../../components/Tooltip";
import ZenDropdown from "../../../../components/ZenDropdown";
import ApiClient from "../../../../lib/ApiClient";
import {EAlertType, GlobalAlertContext} from "../../../../components/GlobalAlert";
import ZenMiniAlert from "../../../../components/ZenMiniAlert";
import ZenDeleteButton from "../../../../components/ZenDeleteButton";
import {GlobalContext} from "../../../../lib/GlobalContextProvider";
import ZenButton from "../../../../components/ZenButton";
import {IZenContext} from "@yellowmelon/zen-global-types";


const apiClient = new ApiClient()

interface Props{
    // The props below are for setting the context on a parent object, e.g. a goal
    // The default behaviour is to update the global context - e.g. for the kanban board
    parentContext?: string | null
    updateParentContext?: (context: string | null) => void
}

const ManageContext = ({parentContext, updateParentContext}: Props) => {

    const {getContexts, availableContexts, currentContext, updateContext} = useContext(GlobalContext)
    const [showModal, setShowModal] = useState<boolean>(false)
    // Keep context locally in case you don't want to update it globally
    const [localContext, setLocalContext] = useState<IZenContext | null>(currentContext)

    // newContext for creating a new context
    const [newContext, setNewContext] = useState<IZenContext>({name: ''})
    const {showAlert} = useContext(GlobalAlertContext)
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);


    // Update global or parent context
    const doUpdate = (newIndex: number | null)=>{

        const newContext = typeof newIndex === 'number' ?  availableContexts[newIndex] : null

        if(!updateParentContext){ // Update the global context

            if(newContext?._id === localContext?._id) return;
            updateContext( newContext)
            return;
        }

        const newContextId = newContext?._id?.toString() || null;
        updateParentContext(newContextId)

    }

    useEffect(() => {

        // Update comes from global context
        if(!updateParentContext){

            if(currentContext === localContext) return;

            setLocalContext(currentContext)
            return;
        }

        const context = availableContexts.find((avContext)=> avContext._id === parentContext)
        setLocalContext(context ? context : null)

    }, [currentContext, parentContext, availableContexts]);

    const closeModal = (ev: any) => {

        ev.stopPropagation();
        setShowModal(false);

    }

    const addContext = async (ev: any) => {

        ev.preventDefault();
        ev.stopPropagation();

        try {

            await apiClient.post(`api/v1/contexts`, {payload: {context: newContext.name}});
            setSaveSuccess(true);

            setNewContext({name: ''})
            getContexts();

            setTimeout(
                () => {

                    setSaveSuccess(false);

                }, 3000
            )


        } catch (err: any) {
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
            console.error(`${err.message}`);
        }

    }


    const deleteContext = (context: IZenContext)=>{

        apiClient.delete(`api/v1/contexts/${context._id}`).then(
            ()=>{

                getContexts();

            }
        ).catch(
            (err)=>{

                console.error(err);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`)

            }
        )

    }

    return (
        <>
            <div className='add-context-container md:flex items-center'>

                <div className={'flex items-center'}>

                    <label className='text-lg font-bold'>Choose context &nbsp;</label>

                    <Tooltip
                        toolTip={`Create and use contexts to organise your goals and tasks. You could create a 'home' context for example to show only goals and tasks that apply to your home environment.`}
                        character='?'
                        iconClasses={'bg-black text-white w-[500px] mr-3 '}
                    />

                </div>

                <div className={'flex items-center'}>

                    <ZenDropdown<IZenContext>
                        selectNoneOption
                        selectedItem={localContext?._id?.toString()}
                        items={availableContexts}
                        onClick={(index) => {
                            doUpdate(index)
                        }
                        }/>

                    <ZenButton
                        size={'sm'}
                        label={'Manage contexts'}
                        zenType="default"
                        className={'ml-2'}
                        onClick={
                            () => {
                                getContexts()
                                setShowModal(true)
                            }
                        }
                    />


                </div>

            </div>

            {
                showModal && (
                    <>

                        <div onClick={(ev) => {
                            closeModal(ev)
                        }}
                             className="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

                        <div
                            className="hs-overlay w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto">

                            <form onSubmit={addContext}>

                                <div
                                    className={`mt-7 ${showModal ? 'opacity-100' : 'opacity-0'} duration-300 ease-out transition-all md:max-w-2xl md:w-full m-3 md:mx-auto`}
                                    onClick={(e) => {
                                        if (e.target === e.currentTarget) {
                                            closeModal(e);
                                        }
                                    }}
                                >

                                    <div
                                        className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                                        <div
                                            className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                                            <h3 className="font-bold text-gray-800 dark:text-white">
                                                Manage Contexts
                                            </h3>
                                            <button type="button"
                                                    onClick={closeModal}
                                                    className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                                            >
                                                <span className="sr-only">Close</span>
                                                <svg className="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                                        fill="currentColor"/>
                                                </svg>
                                            </button>
                                        </div>

                                        <div className="modal-content p-4 overflow-y-auto">
                                            <div className="mt-1 text-gray-800 dark:text-gray-400">

                                                {!!availableContexts.length &&

                                                    (
                                                        <div className='flex flex-col mb-2'>

                                                            <p className={'text-sm mb-2'}>
                                                                Contexts are a top level way to group your tasks or goals by environment: so 'Home', 'Work', 'Personal', etc.
                                                                We recommend keeping contexts as general as possible, you can further categorise your tasks and goals on a project,
                                                                goal or tag level
                                                            </p>

                                                            <h5 className='font-bold pb-1'>Your contexts</h5>

                                                            <div className='context-list flex justify-center'>

                                                                <ul className='w-[50%]'>
                                                                    {
                                                                        availableContexts.map(
                                                                            (item: IZenContext) =>
                                                                                <li key={item._id?.toString()} className='flex align-middle border py-1 px-4 mb-1 last-of-type:mb-0 rounded bg-blue-400 text-white'>
                                                                                    {item.name}
                                                                                    <ZenDeleteButton
                                                                                        className={'ml-auto'}
                                                                                        confirmMessage={"This will delete the context, tasks will be removed from this context and moved to 'none'. No tasks will not be deleted. Continue?"}
                                                                                        onDelete={ ()=>{  deleteContext(item) }  } />
                                                                                </li>
                                                                        )
                                                                    }
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <div>

                                                    <h5 className='font-bold pb-1 mb-2'>Add new Context</h5>

                                                    <div className="grid gap-y-2">

                                                        <div className="relative flex">

                                                            <input type="text"
                                                                   placeholder={'Enter context name'}
                                                                   value={newContext.name}
                                                                   onChange={(event) => {
                                                                       setNewContext({name: event.target.value})
                                                                   }}
                                                                   className="w-3/4 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                                                                   aria-describedby="title"/>

                                                            <button
                                                                className="btn btn-primary ml-2 inline-block whitespace-nowrap"
                                                                disabled={!newContext.name.length}
                                                                type='submit'>
                                                                Save new context
                                                            </button>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div
                                            className="flex items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">

                                            <div className='ml-auto flex'>

                                                {saveSuccess && <ZenMiniAlert message={'context created successfully'}/>}

                                                <button type="button"
                                                        className="btn btn-default"
                                                        onClick={closeModal}>
                                                    Close
                                                </button>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </form>

                        </div>

                    </>
                )
            }


        </>
    )

}

export default ManageContext
